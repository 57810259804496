<template>
  <section id="notification">
    <b-card text-variant="center">
      <p class="font-weight-bolder mt-0 mb-0 notification-text-justify">
        Notifications
      </p>
      <hr />
      <loading
        :active.sync="loading"
        :can-cancel="true"
        :is-full-page="false"
        color="#4285f4"
        loader="dots"
      />
      <div
        v-for="notification in notificationData"
        :key="notification.id"
        v-show="!loading"
      >
        <b-row>
          <b-col lg="8" md="8" sm="12" xs="12">
            <p
              class="media-heading mb-0 font-weight-bolder notification-text-justify"
            >
              {{ notification.title }}
            </p>
            <p class="notification-text-justify">
              {{ notification.description }}
            </p>
          </b-col>
          <b-col
            lg="2"
            md="2"
            sm="6"
            xs="12"
            class="d-flex justify-content-center align-items-center"
          >
            {{ notification.type }}
          </b-col>
          <b-col
            lg="2"
            md="2"
            sm="6"
            xs="12"
            class="d-flex justify-content-center align-items-center"
          >
            {{
              notification.created_at
                ? notification.created_at.slice(0, 10)
                : "None"
            }}
          </b-col>
        </b-row>

        <hr />
      </div>

      <b-pagination
        v-model="currentPage"
        :total-rows="row"
        :per-page="per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        align="right"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </section>
</template>

<script>
import { BCard, BPagination, BRow, BCol } from "bootstrap-vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "@axios";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BCard,
    BPagination,
    BRow,
    BCol,
    Loading,
  },
  computed: {
    row() {
      return this.totalNotifications;
    },
  },
  watch: {
    currentPage(newValue) {
      this.currentPage = newValue;
      this.getAllNotifications();
    },
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    let notificationData = ref([]);
    let currentPage = ref(1);
    let per_page = ref(20);
    let totalNotifications = ref(0);
    let loading = ref(false);

    const getAllNotifications = () => {
      loading.value = true;
      axios
        .get(`/notifications/user/${userData.id}`, {
          params: {
            page: currentPage.value,
            per_page: per_page.value,
            is_read: true,
          },
        })
        .then((response) => {
          loading.value = false;
          notificationData.value = [];
          for (const [notificationKey, notificationValue] of Object.entries(
            response.data.items
          )) {
            notificationData.value.push(notificationValue);
          }
          totalNotifications.value = response.data.total;
        })
        .catch((err) => {
          loading.value = false;
          console.log(err);
        });
    };
    getAllNotifications();

    return {
      notificationData,
      currentPage,
      per_page,
      totalNotifications,
      getAllNotifications,
      loading,
    };
  },
};
</script>

<style>
.notification-text-justify {
  text-align: justify;
  margin: 0;
}
</style>
